import { Navbar, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";
import MewingLogo from "../assets/mewing_cat_circle.png";
import sound from "../assets/sound.svg";
import mute from "../assets/mute.svg";
import photo from "../assets/photo.svg";
import nophoto from "../assets/nophoto.svg";
import menulist from "../assets/list-svgrepo-com.svg";

const CustomNavbar = ({ isMuted, toggleMute, setFlashon, setMute }) => {
  const navigate = useNavigate();
  const [flash, setFlash] = useState(false);

  function localSetFlashon() {
    setFlashon();
    setFlash(!flash);
  }

  const handleDropdownClick = (action) => {
    switch (action) {
      case "home":
        navigate("/");
        toggleMute();
        break;
      case "twitter":
        window.location.href = "https://twitter.com/MewCatAvax";
        break;
      case "telegram":
        window.location.href = "https://t.me/mewingcatavax9000";
        break;
      // case "buymaxx":
      //   navigate("/buymaxx");
      //   toggleMute();
      //   break;
      default:
        console.log("No action defined for: ", action);
    }
  };

  return (
    <Navbar
      id="custom-navbar"
      data-testid="custom-navbar"
      variant="black"
      expand="lg"
      fixed="top"
      bg="black"
    >
      <div className="NavbarContainer">
        <img
          id="logocat"
          src={MewingLogo}
          alt="Mewing logo"
          style={{ width: "60px", marginRight: "80px" }}
        />
        <Navbar.Text
          className="navnbar-text NavbarItem"
          style={{
            marginRight: "50px",
            fontSize: "17px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={() => {
            navigate("/");
            toggleMute();
          }}
        >
          Home
        </Navbar.Text>
        <Navbar.Text
          className="navnbar-text NavbarItem"
          style={{
            marginRight: "50px",
            fontSize: "17px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={() =>
            (window.location.href = "https://twitter.com/MewCatAvax")
          }
        >
          Twitter
        </Navbar.Text>
        <Navbar.Text
          className="navnbar-text NavbarItem"
          style={{
            marginRight: "50px",
            fontSize: "17px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={() =>
            (window.location.href = "https://t.me/mewingcatavax9000")
          }
        >
          Telegram
        </Navbar.Text>
        {/* <Navbar.Text
          className="navnbar-text NavbarItem"
          style={{
            marginRight: "50px",
            fontSize: "17px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={() => {
            navigate("/buymaxx");
            toggleMute();
          }}
        >
          BUYMAXX
        </Navbar.Text> */}
        <Dropdown id="dropdown_menu">
          <Dropdown.Toggle id="dropdown-basic" variant="">
            <img src={menulist} style={{ width: "30px", height: "auto" }}></img>
          </Dropdown.Toggle>

          <Dropdown.Menu id="dropdownContainer">
            <Dropdown.Item
              onClick={() => handleDropdownClick("home")}
              style={{ color: "white", cursor: "pointer" }}
            >
              Home
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleDropdownClick("twitter")}
              style={{ color: "white", cursor: "pointer" }}
            >
              Twitter
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleDropdownClick("telegram")}
              style={{ color: "white", cursor: "pointer" }}
            >
              Telegram
            </Dropdown.Item>
            {/* <Dropdown.Item
              onClick={() => handleDropdownClick("buymaxx")}
              style={{ color: "white", cursor: "pointer" }}
            >
              Buymaxx
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div id="rightButtons">
        <button
          onClick={() => toggleMute()} // Use the passed function directly
          id="muteButton"
          style={{ background: "none", border: "none" }}
        >
          <img
            src={isMuted ? mute : sound}
            alt={isMuted ? "Unmute" : "Mute"}
            style={{ width: "24px", height: "24px" }}
          />
        </button>

        <button
          onClick={localSetFlashon}
          id="photoButtons"
          style={{ background: "none", border: "none" }}
        >
          <img
            src={flash ? nophoto : photo}
            alt={flash ? "nophoto" : "photo"}
            style={{ width: "24px", height: "24px" }}
          />
        </button>
      </div>
    </Navbar>
  );
};

export default CustomNavbar;

import React, { useEffect, useState, useRef } from "react";
import CustomNavbar from "./Navbar.jsx";
import "./LiquidityPage.css";
import MogCard from "./MogCard.jsx";
import Mogged1 from "../assets/mogged1.png";
import Mogged2 from "../assets/mogged2.png";
import Mogged3 from "../assets/mogged3.jpg";
import Mogged4 from "../assets/mogged4.png";
import Mogged5 from "../assets/mogged5.png";
import Mogged6 from "../assets/mogged6.jpg";
import Mogged7 from "../assets/mogged7.jpg";
import Mogged8 from "../assets/mogged8.png";
import Mogged9 from "../assets/mogged9.jpg";
import Mogged10 from "../assets/mogged10.png";
import notMogged1 from "../assets/1-min.png";
import { Container, Row, Col } from "react-bootstrap";

import {
  PictureRow_1,
  PictureRow_2,
  PictureRow_3,
  PictureRow_4,
  PictureRow_5,
  PictureRow_6,
  PictureRow_7,
  PictureRow_8,
  PictureRow_9,
  PictureRow_10,
  PictureRow_11,
  PictureRow_12,
  PictureRow_13,
  PictureRow_14,
  PictureRow_15,
  PictureRow_16,
  PictureRow_17,
} from "./PictureRow.jsx";

import receipt from "../assets/RECEIPT.png";

const LiquidityPage = () => {
  const [flashon, setFlashon] = useState(true);
  const [flash, setFlash] = useState(false);
  const [flashOpacity, setFlashOpacity] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const shutterSound = useRef(new Audio());
  const backgroundMusic = useRef(
    new Audio(
      "/Britney Manson - Fashion  but only best part  (Tik Tok version).mp3"
    )
  );
  const cheerSound = useRef(new Audio("CROWD CHEER SOUND EFFECT.mp3")); // Reference to the cheer sound effect

  const sadTromboneSound = useRef(
    new Audio("Sad Trombone - Sound Effect (HD).mp3")
  );
  const byebyeSound = useRef(new Audio("byebyeslowed.mp3"));

  const [choicemade, setChoicemade] = useState(false);
  const [rightChoice, setRightChoice] = useState(false);
  const [switchCards, setSwitchCards] = useState(false);
  const [correctChoicesCount, setCorrectChoicesCount] = useState(0);

  const [isSelectedCard1, setIsSelectedCard1] = useState(false);
  const [isSelectedCard2, setIsSelectedCard2] = useState(false);

  const [currentPicIndex, setCurrentPicIndex] = useState(0);

  const topMogger = [notMogged1];
  const topMogger_2 = [notMogged1, notMogged1, notMogged1];
  const topMogger_3 = [
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
  ];
  const topMogger_4 = [
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
  ];
  const topMogger_5 = [
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
  ];

  const topMogger_6 = [
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
  ];

  const topMogger_7 = [
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
    notMogged1,
  ];

  const topMogger_8 = Array(31).fill(notMogged1);
  const topMogger_9 = Array(37).fill(notMogged1);

  /* 142 */

  const soundFiles = [
    "/camera_shutter1.mp3",
    "/camera_shutter2.mp3",
    "/camera_shutter3.mp3",
  ];

  const getRandomSoundFile = () => {
    const randomIndex = Math.floor(Math.random() * soundFiles.length);
    return soundFiles[randomIndex];
  };

  useEffect(() => {
    backgroundMusic.current.loop = true;
    backgroundMusic.current.volume = 0;
    const targetVolume = 0.012;
    const fadeTime = 2000;
    const stepTime = 100;
    let fadeInterval;

    const playMusic = async () => {
      try {
        await backgroundMusic.current.play();
        const volumeIncrement = (targetVolume / fadeTime) * stepTime;
        let currentVolume = 0;

        fadeInterval = setInterval(() => {
          currentVolume += volumeIncrement;
          if (currentVolume >= targetVolume) {
            backgroundMusic.current.volume = targetVolume;
            clearInterval(fadeInterval);
          } else {
            backgroundMusic.current.volume = currentVolume;
          }
        }, stepTime);
      } catch (err) {
        console.error("Background music play failed:", err);
        setIsMuted(true);
      }
    };

    playMusic();

    return () => {
      if (fadeInterval) clearInterval(fadeInterval);
      backgroundMusic.current.pause(); // Pause the music
      backgroundMusic.current.currentTime = 0; // Optionally, reset its time to start
      backgroundMusic.current.src = ""; // Clear the source if you don't plan to resume it later
    };
  }, []);

  useEffect(() => {
    if (flashon) {
      const triggerFlashEffect = () => {
        const randomOpacity = 0.5 + Math.random() * 0.5;
        setFlashOpacity(randomOpacity);

        const randomVolume = 0.02 + Math.random() * 0.07;
        shutterSound.current.src = getRandomSoundFile();
        shutterSound.current.volume = isMuted ? 0 : randomVolume;

        setFlash(true);
        shutterSound.current
          .play()
          .catch((e) => console.error("Shutter sound play failed:", e));
        setTimeout(() => setFlash(false), 100);
      };

      const interval = setInterval(
        triggerFlashEffect,
        2400 + Math.random() * 3000
      );

      return () => clearInterval(interval);
    }
  }, [flashon, isMuted]);

  useEffect(() => {
    const playCheerSound = () => {
      cheerSound.current.volume = isMuted ? 0 : 0.025;

      cheerSound.current
        .play()
        .catch((e) => console.error("Cheer sound play failed:", e));
    };

    const randomTimeBetween10And25Seconds = () => 10000 + Math.random() * 15000;
    const timeout = setTimeout(
      playCheerSound,
      randomTimeBetween10And25Seconds()
    );

    return () => clearTimeout(timeout);
  }, [isMuted]);

  const toggleMute = () => {
    const newMuteState = !isMuted;
    backgroundMusic.current.muted = newMuteState;
    shutterSound.current.muted = newMuteState;
    cheerSound.current.muted = newMuteState;
    byebyeSound.current.muted = newMuteState;
    sadTromboneSound.current.muted = newMuteState;
    setIsMuted(newMuteState);
  };

  return (
    <div className="Liquidity" id="LiquidityPage">
      <header>
        <CustomNavbar
          isMuted={isMuted}
          toggleMute={toggleMute}
          setFlashon={() => setFlashon((prev) => !prev)}
          setMute={setIsMuted}
        />
      </header>
      <Container id="PageContent1">
        <p id="pageTitle">TOP LIQUIDITY PROVIDERS</p>
        <Col>
          <PictureRow_1 PictureArray={topMogger} />
          <PictureRow_2 PictureArray={topMogger_2} />
          <PictureRow_3 PictureArray={topMogger_3} />
          <PictureRow_4 PictureArray={topMogger_4} />
          <PictureRow_5 PictureArray={topMogger_5} />
          <PictureRow_6 PictureArray={topMogger_6} />
          <PictureRow_7 PictureArray={topMogger_7} />
          <PictureRow_8 PictureArray={topMogger_8} />
          <PictureRow_9 PictureArray={topMogger_9} />
        </Col>
      </Container>
      {flash && (
        <div
          className="flash"
          style={{ display: "block", opacity: flashOpacity }}
        />
      )}
    </div>
  );
};

export default LiquidityPage;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./MogCard.css";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Row,
  Col,
  Image,
  Button,
  Card,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const MogCard = ({
  img,
  rightChoice,
  handleChoice,
  isSelected,
  toggleSelection,
}) => {
  return (
    <label
      className={`flashcard-label ${isSelected ? "flashcard-selected" : ""}`}
      onClick={() => {
        toggleSelection();
        handleChoice(rightChoice);
      }}
    >
      <div className="flip-card">
        <div className="front-card">
          <img src={img} alt="mog" />
        </div>
      </div>
    </label>
  );
};

export default MogCard;

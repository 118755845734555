import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./PictureRow.css";

const PictureRow_1 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_1">
      <Row>
        {PictureArray.map((mogger) => (
          <Col className="moggerCol1">
            <img src={mogger} className="moggerImage" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text1">
          MOGGER
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_2 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_2">
      <Row>
        {PictureArray.map((mogger) => (
          <Col className="moggerCol2">
            <img src={mogger} className="moggerImage2" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text2">
          LOOKMAXXERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_3 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_3">
      <Row>
        {PictureArray.map((mogger) => (
          <Col className="moggerCol3">
            <img src={mogger} className="moggerImage3" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text3">
          NOSEBREATHERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_4 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_4">
      <Row>
        {PictureArray.map((mogger) => (
          <Col className="moggerCol4">
            <img src={mogger} className="moggerImage4" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text4">
          PROFITMAXXERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_5 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_5">
      <Row>
        {PictureArray.map((mogger) => (
          <Col className="moggerCol5">
            <img src={mogger} className="moggerImage5" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text5">
          HOLDMAXXERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_6 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_6">
      <Row>
        {PictureArray.map((mogger) => (
          <Col className="moggerCol6">
            <img src={mogger} className="moggerImage6" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text6">
          JEETKILLERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_7 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_7">
      <Row>
        {PictureArray.map((mogger) => (
          <Col className="moggerCol7">
            <img src={mogger} className="moggerImage7" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text7">
          WALLETMAXXERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_8 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_8">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage8" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text8">
          WHALEMAXXERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_9 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_9">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage9" />
          </Col>
        ))}
      </Row>
      <Col className="moggerNameCol">
        <p className="moggerName" id="text9">
          MEWINGMAXXERS
        </p>
      </Col>
    </Container>
  );
};

const PictureRow_10 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_10">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage10" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const PictureRow_11 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_11">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage11" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const PictureRow_12 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_12">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage12" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const PictureRow_13 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_13">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage13" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const PictureRow_14 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_14">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage14" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const PictureRow_15 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_15">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage15" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const PictureRow_16 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_16">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage16" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const PictureRow_17 = ({ PictureArray }) => {
  return (
    <Container className="pictureRow" id="PictureRow_17">
      <Row>
        {PictureArray.map((mogger, index) => (
          <Col key={index} className="moggerCol">
            <img src={mogger} alt="" className="moggerImage17" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export {
  PictureRow_1,
  PictureRow_2,
  PictureRow_3,
  PictureRow_4,
  PictureRow_5,
  PictureRow_6,
  PictureRow_7,
  PictureRow_8,
  PictureRow_9,
  PictureRow_10,
  PictureRow_11,
  PictureRow_12,
  PictureRow_13,
  PictureRow_14,
  PictureRow_15,
  PictureRow_16,
  PictureRow_17,
};

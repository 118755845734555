// App.js
import React, { useState } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./components/HomePage.jsx";
import WelcomePage from "./components/WelcomePage.jsx";
import LiquidityPage from "./components/LiquidityPage.jsx";
import BuyPage from "./components/BuyPage.jsx";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="" exact element={<WelcomePage />} />
      </Routes>
      <Routes>
        <Route path="/home" exact element={<HomePage />} />
      </Routes>
      <Routes>
        <Route path="/liq_moggers" exact element={<LiquidityPage />} />
      </Routes>
      <Routes>
        <Route path="/buymaxx" exact element={<BuyPage />} />
      </Routes>
    </Router>
  );
};

export default App;

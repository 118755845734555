import React, { useEffect, useState, useRef } from "react";
import CustomNavbar from "./Navbar.jsx";
import "./HomePage.css";
import MogCard from "./MogCard.jsx";
import Mogged1 from "../assets/mogged1.png";
import notMogged1 from "../assets/1.png";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./WelcomePage.css";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  const navigate = useNavigate();
  const byebyesong = useRef(new Audio("byebyeslowed.mp3"));

  const handleRejectButtonHover = () => {
    const button = document.getElementById("noButton");
    const newX = Math.random() * (window.innerWidth - button.offsetWidth);
    const newY = Math.random() * (window.innerHeight - button.offsetHeight);
    button.style.left = `${newX}px`;
    button.style.top = `${newY}px`;
  };

  const handleRejectButtonClick = () => {
    const button = document.getElementById("noButton");
    const newX = Math.random() * (window.innerWidth - button.offsetWidth);
    const newY = Math.random() * (window.innerHeight - button.offsetHeight);
    button.style.left = `${newX}px`;
    button.style.top = `${newY}px`;
  };

  const handleAcceptButtonClick = () => {
    byebyesong.current.volume = 0.008;
    byebyesong.current.play();
    const yesButton = document.getElementById("yesButton");
    yesButton.style.opacity = "0";

    setTimeout(() => navigate("/home"), 500);
  };

  return (
    <div className="WelcomePage">
      <Container id="contentContainer">
        <h1 id="invitation">Mewing Cat 2 Invitation</h1>
        <Row id="formButtons">
          <button id="yesButton" onClick={handleAcceptButtonClick}>
            Accept
          </button>
          <button
            id="noButton"
            onMouseEnter={handleRejectButtonHover}
            onClick={handleRejectButtonClick}
          >
            Reject
          </button>
        </Row>
      </Container>
    </div>
  );
};

export default WelcomePage;
